import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {skip} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private route: ActivatedRoute,
              private router: Router) {
    this.verifyDestination();
  }

  verifyDestination() {
    this.route.queryParams.pipe(skip(1)).subscribe(p => {

      // t = bearer token
      // d = destination
      // id = external id // Store Id


      if (p['t'] != null && p['id'] != null && p['d'] != null) {
        localStorage.setItem('accessToken', p['t']);
        localStorage.setItem('id', p['id']);

        if (p['d'] != null) {
          switch (p['d']) {
            case 'register':
              localStorage.setItem('access-type', 'establishment');
              this.router.navigate(['conta-digital']);
              break;
            case 'balance':
              localStorage.setItem('access-type', 'establishment');
              this.router.navigate(['saldo']);
              break;
            case 'order':
              localStorage.setItem('access-type', 'mobile');
              this.router.navigate(['pagamento']);
              break;
            case 'payment-ways':
              localStorage.setItem('access-type', 'establishment');
              this.router.navigate(['configuracao']);
              break;
          }
        }
      }
    });
  }
}
